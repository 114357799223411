<template lang="pug">
  v-layout(wrap)
    .menu-toggle( v-if="$vuetify.breakpoint.mdAndDown" @click="showMenu").ma-2.mt-4
      v-icon fa-bars
    v-flex(v-if="$route.meta.breadcrumb && valueOrFunctionProxy($route.meta.breadcrumb).length>0 ")
      v-breadcrumbs(:items='valueOrFunctionProxy($route.meta.breadcrumb)', divider='>').pl-0
        template(v-slot:item="props")
          v-breadcrumbs-item(:to='toRoute(props.item.to)' exact) {{ valueOrFunctionProxy(props.item.name) }}
</template>

<script>
export default {
  name: 'Breadcrumb',
  methods: {
    valueOrFunctionProxy (valueOrFunction) {
      if (typeof valueOrFunction === typeof (() => {})) {
        return valueOrFunction.bind(this)()
      } else {
        return valueOrFunction
      }
    },
    showMenu () {
      this.$mainBus.$emit('showMenu')
    },
    toRoute (to) {
      if (typeof to === typeof (() => {})) {
        return to.bind(this)()
      } else if (to && to.params && this.$route.params && this.$route.params.id) {
        to.params.id = this.$route.params.id
      }
      return to
    }
  }
}
</script>

<style scoped>

</style>
