import Vue from 'vue'
import Vuex from 'vuex'

import analysis from './modules/Vis/store'
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    analysis
  },
  plugins: [
    createPersistedState({
        paths: ['analysis'],
    }),
  ],
  strict: debug
})
