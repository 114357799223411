export default [
  {
    path: '/',
    name: 'Dashboard',
    component: () => import('./views/Dashboard'),
    meta: {
      breadcrumb: [{
        name: 'Dashboard',
        to: { name: 'Dashboard' }
      }]
    }
  }

]
