export default [
  {
    path: '/Vis/Explore/TagCloud',
    name: 'VisTagCloud',
    component: () => import(/* webpackChunkName: "analyisis" */ './Explore/TagCloud/views/Tagcloud'),
    meta: {
    }
  },
  {
    path: '/Vis/Explore/Timages',
    name: 'VisTimages',
    component: () => import(/* webpackChunkName: "analyisis" */ './Explore/Timages/views/Timages'),
    meta: {
    }
  },
  {
    path: '/Vis/Explore/TimeSpace',
    name: 'VisTimeSpace',
    component: () => import(/* webpackChunkName: "analyisis" */ './Explore/TimeSpace/views/TimeSpace'),
    meta: {
    }
  },  
  {
    path: '/Vis/Labeling/LabelHierarchy',
    name: 'VisLabelHierarchy',
    component: () => import(/* webpackChunkName: "analyisis" */ './Labeling/LabelHierarchy/views/LabelHierarchy'),
    meta: {
    }
  },
  {
    path: '/Vis/Labeling/InterpretiveView',
    name: 'InterpretiveView',
    component: () => import(/* webpackChunkName: "analyisis" */ './Labeling/InterpretiveView/views/InterpretiveView'),
    meta: {
    }
  },
  {
    path: '/Vis/Labeling/TaggingInterpretive',
    name: 'TaggingInterpretive',
    component: () => import(/* webpackChunkName: "analyisis" */ './Labeling/TaggingInterpretive/views/TaggingInterpretive'),
    meta: {
    }
  },  
  {
    path: '/Vis/Labeling/ConflictViewInterpretive',
    name: 'ConflictViewInterpretive',
    component: () => import(/* webpackChunkName: "analyisis" */ './Labeling/ConflictViewInterpretive/views/ConflictViewInterpretive'),
    meta: {
    }
  },    
  {
    path: '/Vis/Explore/WordSpace',
    name: 'VisWordSpace',
    component: () => import(/* webpackChunkName: "analyisis" */ './Explore/WordSpace/views/WordSpace'),
    meta: {
    }
  },
  {
    path: '/Vis/Explore/Map',
    name: 'VisMap',
    component: () => import(/* webpackChunkName: "analyisis" */ './Explore/Map/views/Map'),
    meta: {
    }
  },
  {
    path: '/Vis/Labeling/Recommendation',
    name: 'Recommendation',
    props: true,
    component: () => import(/* webpackChunkName: "analyisis" */ './Labeling/Recommendation/views/Recommendation'),
    meta: {
    }
  },  
  {
    path: '/Vis/Explore/ProfilingView',
    name: 'ProfilingView',
    props: true,
    component: () => import(/* webpackChunkName: "analyisis" */ './Explore/ProfilingView/views/ProfilingView'),
    meta: {
    }
  },
  {
    path: '/Vis/Explore/AlignmentView',
    name: 'AlignmentView',
    props: true,
    component: () => import(/* webpackChunkName: "analyisis" */ './Explore/AlignmentView/views/AlignmentView'),
    meta: {
    }
  },  
  {
    path: '/Vis/Explore/ManuscriptAlignmentView',
    name: 'ManuscriptAlignmentView',
    props: true,
    component: () => import(/* webpackChunkName: "analyisis" */ './Explore/ManuscriptAlignmentView/views/ManuscriptAlignmentView'),
    meta: {
    }
  },    
  {
    path: '/Vis/ImageView',
    name: 'ImageView',
    props: true,
    component: () => import(/* webpackChunkName: "analyisis" */ './ImageView/views/ImageView'),
    meta: {
    }
  },
  {
    path: '/Vis/Explore/ImageCloud',
    name: 'ImageCloud',
    props: true,
    component: () => import(/* webpackChunkName: "analyisis" */ './Explore/ImageCloud/views/ImageCloud'),
    meta: {
    }
  },
  {
    path: '/Vis/Explore/ImagePie',
    name: 'ImagePie',
    props: true,
    component: () => import(/* webpackChunkName: "analyisis" */ './Explore/ImagePie/views/ImagePie'),
    meta: {
    }
  },    
  {
    path: '/Vis/ObjectDetection/ObjectGrid',
    name: 'ObjectGrid',
    props: true,
    component: () => import(/* webpackChunkName: "analyisis" */ './ObjectDetection/ObjectGrid/views/ObjectGrid'),
    meta: {
    }
  },  
  {
    path: '/Vis/ObjectDetection/DetectionResults',
    name: 'DetectionResults',
    props: true,
    component: () => import(/* webpackChunkName: "analyisis" */ './ObjectDetection/DetectionResults/views/DetectionResults'),
    meta: {
    }
  },    
  {
    path: '/Vis/ObjectDetection/ObjectCompareGrid',
    name: 'ObjectCompareGrid',
    props: true,
    component: () => import(/* webpackChunkName: "analyisis" */ './ObjectDetection/ObjectCompareGrid/views/ObjectCompareGrid'),
    meta: {
    }
  },    
  {
    path: '/Vis/ObjectDetection/ObjectClassBuilder',
    name: 'ObjectClassBuilder',
    component: () => import(/* webpackChunkName: "analyisis" */ './ObjectDetection/ObjectClassBuilder/views/ObjectClassBuilder'),
    meta: {
    }
  },
  {
    path: '/Vis/ObjectDetection/DrawBB',
    name: 'DrawBB',
    props: true,
    component: () => import(/* webpackChunkName: "analyisis" */ './ObjectDetection/DrawBB/views/DrawBB'),
    meta: {
    }
  },        
  {
    path: '/Vis/DescripteurView',
    name: 'DescripteurView',
    props: true,
    component: () => import(/* webpackChunkName: "analyisis" */ './DescripteurView/views/DescripteurView'),
    meta: {
    }
  },
  {
    path: '/Vis/Explore/UMAP Words',
    name: 'UMAP',
    props: true,
    component: () => import(/* webpackChunkName: "analyisis" */ './Explore/UMAP Words/views/UMAP Words'),
    meta: {
    }
  },
  {
    path: '/Vis/Explore/Upset Plot',
    name: 'UpsetPlot',
    props: true,
    component: () => import(/* webpackChunkName: "analyisis" */ './Explore/UpsetPlot/views/UpsetPlot'),
    meta: {
    }
  },
  {
    path: '/Vis/Labeling/ManuscriptNetwork',
    name: 'ManuscriptNetwork',
    props: true,
    component: () => import(/* webpackChunkName: "analyisis" */ './Labeling/ManuscriptNetwork/views/ManuscriptNetwork'),
    meta: {
    }
  },
  {
    path: '/Vis/Labeling/Tagging',
    name: 'Tagging',
    props: true,
    component: () => import(/* webpackChunkName: "analyisis" */ './Labeling/Tagging/views/Tagging'),
    meta: {
    }
  }, 
  {
    path: '/Vis/Labeling/ConflictView',
    name: 'ConflictView',
    props: true,
    component: () => import(/* webpackChunkName: "analyisis" */ './Labeling/ConflictView/views/ConflictView'),
    meta: {
    }
  },  
  {
    path: '/Vis/Labeling/ConflictViewTagging',
    name: 'ConflictViewTagging',
    props: true,
    component: () => import(/* webpackChunkName: "analyisis" */ './Labeling/ConflictViewTagging/views/ConflictViewTagging'),
    meta: {
    }
  },  
  {
    path: '/Vis/LoopPipeline/VocabMapper',
    name: 'VocabMapper',
    props: true,
    component: () => import(/* webpackChunkName: "analyisis" */ './LoopPipeline/VocabMapper/views/VocabMapper'),
    meta: {
    }
  },
  {
    path: '/Vis/LoopPipeline/VIAL',
    name: 'VIAL',
    props: true,
    component: () => import(/* webpackChunkName: "analyisis" */ './LoopPipeline/VIAL/views/VIAL'),
    meta: {
    }
  }                              
]
