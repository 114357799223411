export default {
  SET_USER(state, flag) {
    state.user = flag
  },
  SET_SESSION(state, flag) {
    state.session = flag
  },
  SET_GUID(state, flag) {
    state.guid = flag
  },
  SET_INDEX(state, flag) {
    state.taggingIndex = flag
  },
  SET_IMAGES(state, flag) {
    state.taggingImages = flag
  }
}
