import moment from 'moment'

export default function (value, format = null) {
  if (value === null || value === undefined || format === undefined) {
    return ''
  }
  if (format === 'from') {
    return moment(value).fromNow()
  }
  if (format === null) {
    return moment(value).local().format()
  }
  return moment(value).format(format)
}
