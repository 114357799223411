<template lang="pug">
v-navigation-drawer(app clipped)
  template(v-for='item in items')
    v-list-item(v-if='!item.subLinks', :to="item.to"  :key='item.text' :exact="item.exact?item.exact:false")
      v-list-item-action(v-if='item.isIconify')
        <Icon :icon="item.icon" height="24"/>
      v-list-item-action(v-else)
        v-icon {{ item.icon }}  fa-fw        
      v-list-item-content
        v-list-item-title {{ item.text }}
    v-list-group(v-else, :key='item.text', no-action)
      template(v-slot:activator)
        v-list-item-action(v-if='item.isIconify')
          <Icon :icon="item.icon" height="24"/>
        v-list-item-action(v-else)
          v-icon {{ item.icon }}  fa-fw        
        v-list-item-content
          v-list-item-title {{ item.text }}  
      v-list-item(v-for="subItem in item.subLinks" :key='subItem.text' :to="subItem.to") 
        v-list-item-action(v-if='subItem.isIconify')
          <Icon :icon="subItem.icon" height="24"/>
        v-list-item-action(v-else)
          v-icon {{ subItem.icon }}  fa-fw        
        v-list-item-content
          v-list-item-title {{ subItem.text }}   
</template>


<script>
import VueGravatar from 'vue-gravatar'
import { Icon } from '@iconify/vue2';

/*
  div 
    img(src="@/assets/favicon.png" width='50px')
    div iDaLMAdor
*/

export default {
  name: 'navigationSide',
  components: { VueGravatar, Icon},
  data: function () {
    return {
      remainingMs: 100,
      timer: null,
      items: [
        { icon: 'fa-tachometer-alt', text: 'Dashboard', to: { name: 'Dashboard' }, exact: true },
        { icon: 'fas fa-search', text: "Explore", subLinks : [
          { icon: 'flat-color-icons:timeline', isIconify: true, text: 'TimeSpace', to: { name: 'VisTimeSpace' } },
          { icon: 'codicon:whole-word', isIconify: true, text: "Word Space", to: {name: 'VisWordSpace'}},   
          { icon: 'flat-color-icons:timeline', isIconify: true, text: 'Timeages', to: { name: 'VisTimages' } },
          { icon: 'fa-map', text: 'Map', to: { name: 'VisMap' } },
          { icon: 'fa-images', text: 'Image Cloud', to: { name: 'ImageCloud' } },  
          { icon: 'vaadin:cluster', isIconify: true, text: 'Image Space', to: { name: 'UMAP' } },    
          { icon: 'fa-project-diagram', text: 'Profiling View', to: { name: 'ProfilingView' } },     
          { icon: 'fa-project-diagram', text: 'Alignment View', to: { name: 'AlignmentView' } },
          { icon: 'fa-project-diagram', text: 'Manuscript Alignment View', to: { name: 'ManuscriptAlignmentView' } },   
        ]
        },
        { icon: 'fluent-emoji-high-contrast:label',  isIconify: true, text: "Labeling", subLinks : [
          { icon: 'ph:graph-duotone', isIconify: true, text: 'Manuscript Network', to: { name: 'ManuscriptNetwork' } },
          { icon: 'fas fa-project-diagram', text: 'Label Hierarchy', to: { name: 'VisLabelHierarchy' } },
          { icon: 'fas fa-project-diagram', text: 'Interpretive View', to: { name: 'InterpretiveView' } },  
          { icon: 'fluent-emoji-high-contrast:label', isIconify: true, text: "Label Interpretive", to: {name: 'TaggingInterpretive'}}, 
          { icon: 'fluent-emoji-high-contrast:label', isIconify: true, text: "Results Interpretive", to: {name: 'ConflictViewInterpretive'}},           
        ]},
        { icon: 'material-symbols:detection-and-zone', isIconify: true, text: "Object Detection", subLinks : [
          { icon: 'fa-images', text: 'Object Grid', to: { name: 'ObjectGrid' } },
          { icon: 'fa-images', text: 'Object Compare Grid', to: { name: 'ObjectCompareGrid' } },
          { icon: 'fa-images', text: 'Detection Results', to: { name: 'DetectionResults' } },
        ]},
        {
          icon: 'fa6-solid:person-walking-arrow-loop-left', isIconify: true, text: "Loop Pipeline", subLinks : [
          { icon: 'fa-images', text: 'Vocab Mapper', to: { name: 'VocabMapper' } },
          { icon: 'fluent-emoji-high-contrast:label',  isIconify: true, text: "VIAL", to: {name: "VIAL"}} 
        ]},
        {
          icon: 'icon-park-solid:check-correct', isIconify: true, text: "Correct-a-thon", subLinks : [
          { icon: 'fluent-emoji-high-contrast:label', isIconify: true, text: "Tagging", to: {name: 'Tagging'}},
          { icon: 'bi:ui-checks-grid', isIconify: true, text: 'Conflicts', to: { name: 'ConflictViewTagging' } },
        ]},
        { icon: 'material-symbols:detection-and-zone', isIconify: true, text: "Draw Bounding Box", to: {name: 'DrawBB'}},  
        { icon: 'fa6-solid:person-digging', isIconify: true, text: "Test", subLinks : [
          { icon: 'fa-images', text: 'Image Pie', to: { name: 'ImagePie' } },
          { icon: 'teenyicons:venn-diagram-outline', isIconify: true, text: 'Upset Plot', to: { name: 'UpsetPlot' } },
          { icon: 'iconoir:suggestion', isIconify: true, text: 'Recommendation', to: { name: 'Recommendation' } },
          { icon: 'bi:ui-checks-grid', isIconify: true, text: 'Conflicts', to: { name: 'ConflictView' } },
          { icon: 'fa-images', text: 'Object Class Builder', to: { name: 'ObjectClassBuilder' } },  
        ]},              
        //{ icon: 'fluent:cloud-words-16-filled', isIconify: true, text: "Tag Cloud", to: {name: 'VisTagCloud'}},
        // { icon: 'carbon:image-search', isIconify: true, text: 'Descripteur View', to: { name: 'DescripteurView' } },                                         
      ]
    }
  },
  mounted () {
    let vm = this
    if (this.timer !== null) {
      clearInterval(this.timer)
      this.timer = null
    }
    this.timer = setInterval(vm.updateTimer, 1000)
  },
  unmounted () {
    if (this.timer !== null) {
      clearInterval(this.timer)
      this.timer = null
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>

<style scoped lang ="less">
  .header {
    .theme--light.v-list-item:hover::before {
      opacity: 0.00;
    }
  }
</style>
