export default {
  setUser({commit}, user) {
    commit('SET_USER', user)
  },
  setSession({commit}, session) {
    commit('SET_SESSION', session)
  },
  setGUID({commit}, guid) {
    commit('SET_GUID', guid)
  },  
  setIndex({commit}, taggingIndex) {
    commit('SET_INDEX', taggingIndex)
  },
  setImages({commit}, taggingImages) {
    commit('SET_IMAGES', taggingImages)
  }      
}