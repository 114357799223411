<template lang="pug">
v-app
  navigation-side
  v-main()
    v-container(fluid grid-list-md): v-layout(row wrap): v-flex(xs12)
      breadcrumb
      router-view#routerview
</template>

<script>

import Breadcrumb from './modules/Core/components/breadcrumb'
import navigationSide from './modules/Core/components/navigation-side'
export default {
  name: 'App',
  components: { Breadcrumb, navigationSide },
  data () {
    return {
      showOverlay: false,
    }
  },
  methods: {
  }
}
</script>
