import '@fortawesome/fontawesome-free/css/all.css' // Ensure you are using css-loader
import Vue from 'vue'

import vuetify from './plugins/vuetify'

import './plugins/vee-validate'
import { ValidationProvider } from 'vee-validate'

import App from './App.vue'
import router from './router'
import store from './store'

import momentFilter from './plugins/filter.moment'
import JsonViewer from 'vue-json-viewer'

import VueAWN from 'vue-awesome-notifications'
import 'vue-awesome-notifications/dist/styles/style.css'
import vueFilterPrettyBytes from 'vue-filter-pretty-bytes'
import 'vue-search-select/dist/VueSearchSelect.css'
/*import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);*/

import VueBsDrawer from 'vue-bs-drawer'
Vue.component('bs-drawer', VueBsDrawer)

import { BasicSelect } from 'vue-search-select'

Vue.use(vueFilterPrettyBytes)

Vue.use(VueAWN, {})
Vue.use(JsonViewer)

Vue.filter('moment', momentFilter)

Vue.component('basic-select', BasicSelect);

import VueSimpleSuggest from 'vue-simple-suggest'
Vue.component('vue-simple-suggest', VueSimpleSuggest)
// Optional - import css separately with css loaders:
import 'vue-simple-suggest/dist/styles.css'

Vue.config.productionTip = false
Vue.component('ValidationProvider', ValidationProvider)

// leaflet marker are not loaded
import L from "leaflet"

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
   iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
   iconUrl: require('leaflet/dist/images/marker-icon.png'),
   shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
