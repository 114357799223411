import Vue from 'vue'
import Router from 'vue-router'

import CoreRoutes from './modules/Core/routes'
import VisRoutes from './modules/Vis/routes'

Vue.use(Router)
const fallbackRoute = [{ path: '*', redirect: '/' }]

const routes = CoreRoutes
  .concat(VisRoutes)
  .concat(fallbackRoute)

export default new Router({
  mode: 'history',
  base: process.env.BABEL_URL,
  routes
})